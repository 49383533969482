















import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, NavBar } from "vant";
import MobileBond from "./Components/MobileBond.vue";
@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [NavBar.name]: NavBar,
    MobileBond,
  },
})
export default class WechatBind extends Mixins(Mixin) {
  openId = "";
  unionId = "";

  mounted(): void {
    let oldOpenId = this.$route.query.openId;
    let oldUnionId = this.$route.query.unionId;
    this.openId = oldOpenId ? String(oldOpenId) : "";
    this.unionId = oldUnionId ? String(oldUnionId) : "";
  }
}
